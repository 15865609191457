<template>
  <section class="app-ecommerce-details">
    <b-modal
      id="modal-1"
      :title="$t('Pages.Shop.Title.pay_confirm')"
      ok-only
      :ok-title="$t('Pages.Shop.Button.Payment')"
      @ok="goToPaymentPage"
    >
      <b-card-text>
        <b-input-group>
          <b-form-input v-model="coupon" />
          <b-input-group-append>
            <b-button variant="primary" @click="validationCoupon">{{
              $t("Pages.Shop.Button.regis_discount")
            }}</b-button>
          </b-input-group-append>
        </b-input-group>
        <p class="mt-2" v-if="price_discount">
          {{
            $t("Pages.Shop.Title.amount_payable",{amount:price_discount})+" " + $t("Tooman")
          }}
        </p>
      </b-card-text>
    </b-modal>

    <!-- Content -->
    <b-card v-if="showProduct" no-body>
      <b-card-body class="position-relative">
        <span
          class="position-absolute p-2"
          style="top: 0; left: 0"
          v-if="product.show_timer"
        >
          <countdown
            :end-time="new Date(product.end_time).getTime()"
            v-if="product.price"
          >
            <template v-slot:process="anyYouWantedScopName">
              <b-badge variant="light-danger">{{
                $t("Pages.Shop.Title.until_end_discount")
              }}</b-badge>
              <b-badge variant="light-warning">
                <span v-if="anyYouWantedScopName.timeObj.d != 0">{{
                  `${anyYouWantedScopName.timeObj.d}` + $t("Pages.Shop.Title.Day") + " "
                }}</span>
                <span v-if="anyYouWantedScopName.timeObj.h != 0">{{
                  `${anyYouWantedScopName.timeObj.h}` + $t("Pages.Shop.Title.Hour") + " "
                }}</span>

                <span v-if="anyYouWantedScopName.timeObj.m != 0">{{
                  `${anyYouWantedScopName.timeObj.m}` + $t("Pages.Shop.Title.Minute") + " "
                }}</span>

                <span v-if="anyYouWantedScopName.timeObj.s != 0">{{
                  `${anyYouWantedScopName.timeObj.s}` + $t("Pages.Shop.Title.Second") + " "
                }}</span>
              </b-badge>
            </template>
          </countdown>
        </span>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="align-items-center justify-content-top mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="product.image"
                :alt="`Image of ${product.title}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>{{ product.title }}</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              {{ product.name }}
            </b-card-text>

            <!-- Price And Ratings -->
            <div
              class="ecommerce-details-price d-flex flex-wrap mt-1"
              v-if="product.show_discount"
            >
              <h5 class="item-price mr-1 text-danger">
                <s>
                  {{ product.original_price.toLocaleString() }}
                  {{ $t("Pages.Shop.Title.Tooman") }}</s
                >
              </h5>
              <h4 class="item-price mr-1">
                {{ product.price.toLocaleString() }} {{ $t("Pages.Shop.Title.Tooman") }}
              </h4>
            </div>
            <div class="ecommerce-details-price d-flex flex-wrap mt-1" v-else>
              <h4 class="item-price mr-1">
                {{ product.original_price.toLocaleString() }} {{ $t("Pages.Shop.Title.Tooman") }}
              </h4>
            </div>

            <!-- Avability -->
            <b-card-text>
              <span class="text-success" v-if="product.active != 0">{{
                $t("Its Active")
              }}</span>
              <span class="text-danger" v-else>{{ $t("Pages.Shop.Title.ComingSoon") }}</span>
            </b-card-text>

            <!-- Product Description -->
            <b-card-text
              class="pre-formatted info"
              v-html="product.desc"
            ></b-card-text>
            <hr />
            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                v-b-modal.modal-1
                v-if="product.active != 0"
              >
                <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                <span>{{ $t("Buy") }}</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                v-else
              >
                <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                <span>{{ $t("Pages.Shop.Title.ComingSoon") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <div class="item-features">
        <div class="row text-center">
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="w-75 mx-auto">
              <feather-icon icon="AwardIcon" size="35" />
              <h4 class="mt-2 mb-1">
                {{ $t("Pages.Shop.Title.shopSupport_title1") }}
              </h4>
              <p class="card-text">
                {{ $t("Pages.Shop.Title.shopSupport_txt1") }}
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="w-75 mx-auto">
              <feather-icon icon="ClockIcon" size="35" />
              <h4 class="mt-2 mb-1">
                {{ $t("Pages.Shop.Title.shopSupport_title2") }}
              </h4>
              <p class="card-text">
                {{ $t("Pages.Shop.Title.shopSupport_txt2") }}
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="w-75 mx-auto">
              <feather-icon icon="ShieldIcon" size="35" />
              <h4 class="mt-2 mb-1">
                {{ $t("Pages.Shop.Title.shopSupport_title3") }}
              </h4>
              <p class="card-text">
                {{ $t("Pages.Shop.Title.shopSupport_txt3") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <b-card-body>
        <div class="mt-4 mb-2 text-center">
          <h4>{{ $t("Pages.Shop.Title.related_products") }}</h4>
        </div>

        <!-- Swiper -->
        <swiper
          v-if="showProduct"
          class="swiper-responsive-breakpoints px-4 py-2"
          :options="swiperOptions"
        >
          <swiper-slide
            v-for="(product, index) in relatedProducts"
            :key="index"
          >
            <b-link
              :to="{ path: '/shop/info', query: { id: product.product_id } }"
            >
              <div class="item-heading">
                <h5 class="text-truncate mb-0">
                  {{ product.title }}
                </h5>
              </div>
              <div class="img-container w-80 mx-auto py-75">
                <b-img :src="product.image" fluid />
              </div>
              <div class="item-meta">
                <p
                  class="card-text text-primary mb-0"
                  v-if="product.price != 0"
                >
                  {{ product.price.toLocaleString() }} {{ $t("Pages.Shop.Title.Tooman") }}
                </p>
                <p class="card-text text-primary mb-0" v-else>
                  {{ product.original_price.toLocaleString() }}
                  {{ $t("Pages.Shop.Title.Tooman") }}
                </p>
              </div>
            </b-link>
          </swiper-slide>

          <!-- Add Arrows -->
          <div slot="button-next" class="swiper-button-next" />
          <div slot="button-prev" class="swiper-button-prev" />
        </swiper>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BModal,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    Swiper,
    SwiperSlide,
    BModal,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BBadge,
  },
  data() {
    return {
      product: {},
      showProduct: false,
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 55,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1600: {
            slidesPerView: 4,
            spaceBetween: 55,
          },
          1300: {
            slidesPerView: 3,
            spaceBetween: 55,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 55,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 55,
          },
        },
      },
      relatedProducts: [],
      price_discount: null,
      coupon: "",
    };
  },
  created() {
    this.getProduct();
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getProduct();
      }
    },
  },
  methods: {
    getProduct() {
      this.$http
        .get(`/api/shop/product/${this.$route.query.id}/info`)
        .then((response) => {
          this.showProduct = true;
          this.product = response.data.product;
          this.relatedProducts = response.data.related;
        })
        .catch((error) => {
          this.showProduct = true;
        });
    },
    validationCoupon() {
      this.$http
        .post("/api/coupon/check/shopping", {
          product: this.product.id,
          code: this.coupon,
        })
        .then(({ data }) => {
          if (data.status) {
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Pages.Shop.Alert.discount_success_applied"),
              "success"
            );
            this.price_discount = data.price;
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Pages.Shop.Alert.discount_invalid"),
              "danger"
            );
          }
        })
        .catch(() => {
          this.price_discount = null;
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Pages.Shop.Alert.discount_invalid"),
            "danger"
          );
        });
    },
    goToPaymentPage() {
      this.makeToast(
        this.$t("Base.Alert.please_wait"),
        this.$t("Pages.Shop.Alert.transfer_portal"),
        "success"
      );
      this.$http
        .post(`/api/shop/product/buy`, {
          product_id: this.product.product_id,
          code: this.coupon,
        })
        .then((response) => {
          window.location.href = response.data.url;
        })
        .catch(() => {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Pages.Shop.Alert.transfer_portal"),
            "danger"
          );
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";

.info img {
  width: 100%;
  height: auto;
}

.info iframe {
  width: 100%;
  min-height: 400px;
}

@media screen and (max-width: 540px) {
  .info iframe {
    width: 100%;
    height: auto;
    min-height: 0px;
  }
}
</style>
